<template>
  <div class="list-wrap wrapper">
    <BaseLoad v-if="loading" />
    <div class="list" v-else>
      <h2>我的订单</h2>
      <ListLoad v-if="listLoad && !loading" />
      <ul v-if="!listLoad">
        <li class="list-item" v-for="(item, index) in list" :key="index">
          <div class="between">
            <p class="order-num">订单编号：{{ item.number }}</p>
            <span class="pay" v-if="item.status == 1">已付款</span>
            <span class="cancel" v-else-if="item.status == 2">已取消</span>
            <span class="unpay" v-else>未支付</span>
          </div>
          <div class="flex item">
            <img class="item-l" :src="preSrc + item.goods.cover" alt="" />
            <div class="item-r">
              <h3 class="ellipsis" :title="item.goods.name">
                {{ item.goods.name }}
              </h3>
              <p class="desc ellipsis">{{ item.goods.desc }}</p>
              <p class="between">
                <span class="price">￥{{ item.price }}</span>
                <button
                  class="cancel-btn"
                  v-if="item.status == 0"
                  @click="handleCancel(item.number)"
                >
                  取消订单
                </button>
              </p>
            </div>
          </div>
        </li>
      </ul>
      <BaseEmpty v-if="!listLoad && !loading && list.length == 0" />
      <!-- 弹窗begin -->
      <transition name="fade">
        <div
          class="mask-container"
          :class="[isShowMask ? 'mask-show' : 'mask-hide']"
          v-if="isShowMask"
        >
          <div class="mask-bg" @click="hideMask"></div>
          <div class="mask-con">
            <h3>是否取消订单？</h3>
            <div class="around">
              <button class="btn cancel-btn" @click="hideMask">否</button>
              <button class="btn" @click="cancelPay">是</button>
            </div>
          </div>
        </div>
      </transition>
      <!-- 弹窗end -->
      <!-- 分页 -->
      <div class="pagination-con" v-if="list.length > 0 && total > 3">
        <el-pagination
          :page-size="pageSize"
          :pager-count="5"
          layout="prev, pager, next"
          :total="total"
          :current-page="page"
          @current-change="handleCurrentChange"
        >
        </el-pagination>
      </div>
      <!-- 分页end -->
    </div>
  </div>
</template>
<script>
import BaseEmpty from "@/components/empty/BaseEmpty.vue";
import BaseLoad from "@/components/loading/BaseLoading.vue";
import ListLoad from "@/components/loading/ListLoading.vue";
export default {
  components: {
    BaseEmpty,
    BaseLoad,
    ListLoad,
  },
  data() {
    return {
      preSrc: config.src,
      listLoad: false,
      loading: true,
      list: [],
      page: 1,
      total: 0,
      pageSize: 3,
      isShowMask: false,
      number: "", //订单id
    };
  },
  created() {
    let _self = this;
    _self.orderList(_self.page);
  },
  methods: {
    // 我的订单
    orderList(page) {
      let _self = this;
      _self.axios
        .post(
          config.url.orderList,
          _self.$qs.stringify({ page, size: _self.pageSize })
        )
        .then((res) => {
          if (res.code == 200) {
            _self.list = res.data.list;
            _self.total = res.data.total;
            _self.loading = false;
            _self.listLoad = false;
          } else if (res.code != 200 && res.code != 401) {
            _self.showTip("error", res.msg);
            _self.loading = false;
            _self.listLoad = false;
          }
        });
    },
    hideMask() {
      let _self = this;
      _self.isShowMask = false;
    },
    handleCancel(id) {
      let _self = this;
      _self.isShowMask = true;
      _self.number = id;
    },
    // 取消支付
    cancelPay() {
      let _self = this;
      _self.axios
        .post(
          config.url.orderCancel,
          _self.$qs.stringify({ number: _self.number })
        )
        .then((res) => {
          if (res.code == 200) {
            _self.isShowMask = false;
            _self.showTip("success", "取消订单成功");
            _self.page = 1;
            _self.orderList(1);
          } else if (res.code != 200 && res.code != 401) {
            _self.showTip("error", res.msg);
          }
        });
    },
    // 改变分页
    handleCurrentChange(val) {
      let _self = this;
      _self.page = val;
      _self.orderList(val);
    },
  },
};
</script>
<style lang="scss" scoped>
.container {
  background: #f9f9f9;
  .content {
    background: #fafafa;
  }
}
.con {
  display: flex;
  margin-top: 60px;
  background: #f9f9f9;
  .tab {
    width: 190px;
    height: 440px;
    box-sizing: border-box;
    margin-right: 30px;
    border-radius: 10px;
    background: #fff;
    h3 {
      font-size: 20px;
      font-weight: bold;
      padding: 30px 15px;
      box-sizing: border-box;
    }
    h3::before {
      content: "";
      display: inline-block;
      background: url("../../assets/img/home/pre-icon.png") center center
        no-repeat;
      background-size: 100%;
      width: 10px;
      height: 10px;
      margin-right: 6px;
    }
    li {
      padding: 15px 35px;
      box-sizing: border-box;
      // width: 190px;
      border-right: 2px solid #fff;
      margin-bottom: 20px;
    }
    li:hover {
      cursor: pointer;
      color: $color-main;
      border-right: 2px solid $color-main;
      background: #fff7f1;
    }
    li.active {
      color: $color-main;
      border-right: 2px solid $color-main;
      background: #fff7f1;
    }
  }
  .list {
    width: 980px;
    padding: 30px;
    box-sizing: border-box;
    background: #fff;
    border-radius: $border-radius-main;
    h2 {
      margin-bottom: 40px;
    }
    .list-item {
      background: #f9f9f9;
      padding: 20px 30px;
      box-sizing: border-box;
      border-radius: $border-radius-main;
      margin-bottom: 30px;
      .pay {
        color: #03cca6;
      }
      .unpay {
        color: #34c3f9;
      }
      .cancel {
        color: $color-main;
      }
      .order-num {
        margin-bottom: 20px;
        color: #9f9f9f;
        font-size: 16px;
      }
    }
  }
  .item {
    .item-l {
      width: 200px;
      height: 130px;
      object-fit: contain;
      flex-shrink: 0;
      flex-grow: 0;
      border-radius: $border-radius-main;
    }
    .item-r {
      width: 660px;
      height: 130px;
      flex-shrink: 0;
      flex-grow: 0;
      padding: 4px 30px 10px;
      box-sizing: border-box;
      border-left: 0;
      border-radius: 0 10px 10px 0;
      display: flex;
      flex-direction: column;
      h3 {
        font-size: 20px;
        font-weight: bold;
        margin-bottom: 15px;
      }
      .desc {
        font-size: 16px;
        color: $font-color-51;
        margin-bottom: 20px;
      }
      .price {
        font-size: 20px;
        color: $color-main;
        font-weight: bold;
      }

      .itme-r-b {
        background: $font-color-9f;
      }
    }
  }
  .cancel-btn {
    width: 100px;
    height: 32px;
    line-height: 31px;
    font-size: 14px;
    text-align: center;
    border: 1px solid #bebebe;
    border-radius: 30px;
    color: #818181;
    background: #fff;
  }
  .mask-bg {
    background: rgba(0, 0, 0, 0.5);
  }
  .mask-con {
    padding: 30px;
    box-sizing: border-box;
    border-radius: 15px;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.1);
    h3 {
      text-align: center;
      margin-bottom: 30px;
    }
    .btn {
      width: 100px;
      height: 32px;
      line-height: 31px;
      font-size: 16px;
    }
  }
  .cancel-btn:hover,
  .btn:hover {
    cursor: pointer;
  }
}
</style>
